<template>
  <NuxtLink :to="item?.link || '/'">
    <div class="relative">
      <div
        class="aspect-h-1 aspect-w-1 cursor-pointer"
        :style="{
          filter: `brightness(${data.image_brightness_filter || 100}%)`,
          backgroundColor: data.imageBackgroundColor?.hexa || 'transparent',
        }"
      >
        <UiLazyImg
          class="h-full w-full"
          :src="
            getImageByPosition(item.medias, 'list')?.file?.url ||
            '/images/no-image.webp'
          "
          :alt="imageAlt(item)"
          img-classes="object-cover"
        />
      </div>
      <h3
        class="ui-text-captions lg:ui-text-l absolute bottom-2 w-full uppercase text-black"
        :style="
          { textAlign: data?.card_content_position || 'left' } as StyleValue
        "
      >
        {{ item.currentTranslation?.title }}
      </h3>
    </div>
  </NuxtLink>
</template>

<script lang="ts" setup>
import { BlockEntitiesContent, BlockEntity, EntityData } from "ecom-types";
import { StyleValue } from "vue";

defineProps<{
  data: BlockEntitiesContent;
  item: EntityData & Pick<BlockEntity, "active" | "link">;
}>();
const { t } = useI18n();

function imageAlt(item: EntityData & Pick<BlockEntity, "active" | "link">) {
  if (getImageByPosition(item.medias, "list")?.alt) {
    return getImageByPosition(item.medias, "list")?.alt;
  } else if (item.currentTranslation?.title) {
    return `${t("image")} ${item.currentTranslation?.title}`;
  } else {
    return t("image");
  }
}
</script>
